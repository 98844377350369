import { createReducer, on } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";
import * as GroundingSelectedActions from "./grounding-selected.actions";

export interface State {
  details: GroundingDto;
  isFetching: boolean;
  fetched: boolean;
}

export const initialState: State = {
  details: null,
  fetched: false,
  isFetching: false,
};

export const groundingSelectedReducer = createReducer(
  initialState,

  on(GroundingSelectedActions.LoadGroundingById, (state, action) => state),
  on(GroundingSelectedActions.LoadGroundingByIdSuccess, (state, action) => state),
  on(GroundingSelectedActions.LoadGroundingByIdFailure, (state) => state),
  on(GroundingSelectedActions.ToggleGroundingPreference, (state, action) => state),
  on(
    GroundingSelectedActions.ToggleGroundingPreferenceSuccess,
    (state, action) => state
  )
);
