import { createReducer, on } from "@ngrx/store";
import { GroundingFilterDto } from "src/app/core/models/grounding-filter-dto";
import { VehicleDto } from "src/app/core/models/vehicle-dto";
import * as GroundingReturnedActions from "./grounding-returned.actions";

export interface State {
  filters: GroundingFilterDto;
  list: VehicleDto[];
  isFetching: boolean;
  fetched: boolean;
  page: number;
}

export const initialState: State = {
  filters: {
    quotationWaiting: true,
    quotationSent: true,
    preemptionConfirmed: true,
    preemptionAchievable: true,
    quotationRejected: false,
    quotationNotAchievable: false
  },
  list: [],
  fetched: false,
  isFetching: false,
  page: 1,
};

export const groundingReturnedReducer = createReducer(
  initialState,

  on(GroundingReturnedActions.LoadGroundingReturned, (state, action) => ({
    ...state,
    isFetching: true,
  })),
  on(
    GroundingReturnedActions.LoadGroundingReturnedSuccess,
    (state, action) => ({
      ...state,
      list: [...state.list, ...action.records],
      isFetching: false,
      fetched: true,
    })
  ),
  on(GroundingReturnedActions.LoadGroundingReturnedFailure, (state) => ({
    ...state,
    isFetching: false,
    fetched: true,
  })),
  on(GroundingReturnedActions.SetGroundingReturnedFilter, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      ...action.filter,
    },
  })),
  on(
    GroundingReturnedActions.ApplyGroundingReturnedFilters,
    (state, action) => ({
      ...state,
      list: []
    })
  ),
  on(
    GroundingReturnedActions.ResetGroundingReturnedFilters,
    (state, action) => ({
      ...state,
      list: [],
      page: 1,
      filters: {...initialState.filters},
    })
  ),
  on(
    GroundingReturnedActions.SetGroundingReturnedNextPage,
    (state, action) => state
  ),
  on(
    GroundingReturnedActions.ToggleGroundingReturnedPreferenceListSuccess,
    (state, action) => state
  ),
  on(
    GroundingReturnedActions.CleanGroundingReturnedListState,
    state => state
  )
);
