import { createReducer, on } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";
import { GroundingFilterDto } from "src/app/core/models/grounding-filter-dto";
import * as GroundingActions from "./grounding.actions";

export interface State {
  filters: GroundingFilterDto;
  list: GroundingDto[];
  isFetching: boolean;
  fetched: boolean;
  page: number;
  canLoadMoreRecords: boolean;
}

export const initialState: State = {
  filters: {
    quotationWaiting: true,
    quotationSent: true,
    preemptionConfirmed: true,
    preemptionAchievable: true,
    quotationRejected: false,
    quotationNotAchievable: false
  },
  list: [],
  fetched: false,
  isFetching: false,
  page: 1,
  canLoadMoreRecords: true,
};

export const groundingReducer = createReducer(
  initialState,

  on(GroundingActions.LoadGrounding, (state, action) => state),
  on(GroundingActions.ApplyGroundingFilters, (state) => state),
  on(GroundingActions.LoadGroundingSuccess, (state, action) => state),
  on(GroundingActions.LoadGroundingFailure, (state) => state),
  on(GroundingActions.SetGroundingFilter, (state, action) => state),
  on(GroundingActions.ResetGroundingFilters, (state, action) => state),
  on(GroundingActions.SetGroundingNextPage, (state, action) => state),
  on(GroundingActions.ToggleGroundingPreferenceListSuccess,
    (state, action) => state
  )
);
