/* tslint:disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, UserFormDataDto } from '../models/user-dto';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient
    ) {
    
  }

  setToken(token:string){
    sessionStorage.setItem('accessToken', token);
  }

  setUserData(userData: User){
    localStorage.setItem('firstname', userData.firstname ? userData.firstname : "");
    localStorage.setItem('lastname', userData.lastname ? userData.lastname : "");
    localStorage.setItem('username', userData.username ? userData.username : "");
    localStorage.setItem('userId', userData?.id?.toString());
    localStorage.setItem('newsInfo[unread]', userData?.newsInfo.unread.toString());
    localStorage.setItem('languageCode', userData.locale?.substr(0, 2));
    localStorage.setItem('passwordExpired', String(userData.passwordExpired));

    localStorage.setItem('meta[rmk_createaccount]', String(userData.meta?.security?.attributes?.rmk_createaccount));
    localStorage.setItem('meta[rmk_makeoffer]', String(userData.meta?.security?.attributes?.rmk_makeoffer));
    localStorage.setItem('meta[rmk_receivecom]', String(userData.meta?.security?.attributes?.rmk_receivecom));
    localStorage.setItem('meta[rmk_codice_as400]', String(userData.meta?.security?.attributes?.rmk_codice_as400));
    localStorage.setItem('meta[main_account]', String(userData.meta?.security?.main_account));
    localStorage.setItem('meta[grounding_enabled]', String(userData.meta?.security?.grounding_enabled));

    // unused
    sessionStorage.setItem('buyerData', JSON.stringify(userData.buyer));
  }

  isLogged():boolean{
    let logged = false;
    if (this.getToken()) logged = true;
    return logged;
  }

  getToken():string {
    return sessionStorage.getItem('accessToken');
  }

  logout(): void {
    this.http.get(environment.apiUrl + "logout").subscribe();
    localStorage.clear();
    sessionStorage.clear();
  }

  me():Observable<User>{ 
    return this.http.get<{data: User, meta: any}>(environment.apiUrl + "me").pipe(
      map(res => ({
        ...res.data, 
        ...{meta: res.meta}
      }))
    );
  }

  userFormsData():Observable<UserFormDataDto>{ 
    return this.http.get<UserFormDataDto>(environment.apiUrl + "buyer/user_forms_data");
  }
}

