import {
    FormGroup
} from "@angular/forms";


export const groupByKey = (list: any, key: any) => list.reduce((hash: any, obj: any) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {});


export function deepClone<T>(data:T):T{
    if(data===null || data === undefined) return null;
    return JSON.parse(JSON.stringify(data));
}
